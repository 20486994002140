<template>
	<div class="m_common_content">
		<div class="m_common_top">
			<div class="m_checkdata_content">
				<el-cascader :options="treeData" :show-all-levels="false" :props="cascaderProps" v-model="cascaderValue" placeholder="请选择行政区域" clearable class="inputs"></el-cascader>
				<el-select v-model="franchiseeId" placeholder="请选择加盟商" clearable class="inputs">
					<el-option :label="item.name" :value="item.id" v-for="(item, i) in franchiseeList" :key="i">
					</el-option>
				</el-select>
				<el-select v-model="machstatus" placeholder="请选择联网状态" clearable class='inputs'>
					<el-option label="在线" value="Online"> </el-option>
					<el-option label="离线" value="Offline"> </el-option>
					<el-option label="未知" value="Unknown"> </el-option>
				</el-select>
				<el-select v-model="operstatus" placeholder="请选择运营状态" clearable class='inputs'>
					<el-option label="正常" value="Normal"> </el-option>
					<el-option label="停售" value="Stopped"> </el-option>
					<el-option label="未知" value="Unknown"> </el-option>
				</el-select>
				<el-select v-model="status" placeholder="请选择机器状态" clearable class='inputs'>
					<el-option label="正常" value="Valid"> </el-option>
					<el-option label="故障" value="Invalid"> </el-option>
					<el-option label="未知" value="Unknown"> </el-option>
				</el-select>
				<el-button @click="search" class="m_btn_search">搜索</el-button>
			</div>
			<div class="m_tools_content">
				<el-button @click="exportData" class="m_add m_btn_export">导出</el-button>
				<el-button @click="changeModel('icon')" class="m_add m_btn_common" v-if="contentModel === 'table'">图标模式</el-button>
				<el-button @click="changeModel('table')" class="m_add m_btn_common" v-else>表格模式</el-button>
			</div>
		</div>
		<div class="m_common_middle">
			<div class="m_table_content" v-show="contentModel === 'table'">
				<el-table :data="tableData" stripe>
					<el-table-column label="序号" type="index" width="60" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="code" label="设备编号" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="name" label="名称" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="spec" label="铭牌号码" show-overflow-tooltip>
					</el-table-column>
					<el-table-column label="地址" show-overflow-tooltip>
						<template slot-scope="scope">
							{{ scope.row.location.addr }}
						</template>
					</el-table-column>
					<el-table-column label="点位名称" show-overflow-tooltip>
						<template slot-scope="scope">
							{{ scope.row.location.name }}
						</template>
					</el-table-column>
					<el-table-column label="合伙人" show-overflow-tooltip>
						<template slot-scope="scope">
							{{ scope.row.franchi.name }}
						</template>
					</el-table-column>
					<el-table-column prop="pointName" label="加盟商" show-overflow-tooltip>
						<template slot-scope="scope">
							{{ scope.row.operator.name }}
						</template>
					</el-table-column>
					<el-table-column label="预警温度" show-overflow-tooltip>
						<template slot-scope="scope">
							{{ scope.row.data.alerttemp }}
						</template>
					</el-table-column>
					<el-table-column label="当前温度" show-overflow-tooltip>
						<template slot-scope="scope">
							{{ scope.row.data.curtemp}}
						</template>
					</el-table-column>
					<el-table-column label="系统版本" show-overflow-tooltip>
						<template slot-scope="scope">
							{{ scope.row.data.appver }}
						</template>
					</el-table-column>
					<el-table-column label="状态" show-overflow-tooltip>
						<template slot-scope="scope">
							<i v-if="scope.row.faultStatus === 'Invalid'" style="color: #ff4343">故障</i>
              				<i v-else style="color: #62c462">正常</i>
						</template>
					</el-table-column>
					<el-table-column label="机器状态" show-overflow-tooltip>
						<template slot-scope="scope">
							<i v-if="scope.row.machineStatus === 'Online'" style="color: #62c462">在线</i>
              				<i v-else style="color: #ff4343">离线</i>
						</template>
					</el-table-column>
					<el-table-column prop="siglevel" label="信号强弱" show-overflow-tooltip>
						<template slot-scope="scope">
							{{scope.row.data.siglevel}}
						</template>
					</el-table-column>
					<el-table-column label="操作" width="130" fixed="right" align="center">
						<template slot-scope="scope">
							<i class="el-icon-document m_edit" @click="showUpdate(scope.row)" title="设备详情"></i>
							<i class="el-icon-setting m_edit" @click="distributionPartner(scope.row)" title="分配加盟商"></i>
							<i class="el-icon-circle-close m_edit" @click="machinesInvalid(scope.row)" style="color:#ff000;" title="停售" v-if="scope.row.operateStatus === 'Normal'"></i>
							<i class="el-icon-circle-check m_edit" @click="machinesValid(scope.row)" title="开售" v-else></i>
							<i class="el-icon-key m_edit" @click="RebootSecret(scope.row)" title="重置秘钥"></i>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="m_map_content" v-show="contentModel === 'icon'">
				<div v-for="item in tableData" style="display: inline-block;width:110px;height:140px;margin: 6px;text-align: center;cursor: pointer;" @click="showUpdate(item)">
					<div v-if="item.operateStatus=== 'Normal'">
						<img v-if="item.faultStatus === 'Invalid'" src="../../assets/image/fault.png" alt="" style="width:100px;height: 80px;"/>
						<div v-else>
							<img src="../../assets/image/onLine.png" alt="" style="width:100px;height: 80px;" v-if="item.machineStatus === 'Online'" />
							<img src="../../assets/image/offLine.png" alt="" style="width:100px;height: 80px;" v-if="item.machineStatus === 'Offline'" />
						</div>
					</div>
					<img v-if="item.operateStatus === 'Stopped'"src="../../assets/image/fault.png" alt="" style="width:100px;height: 80px;"/>
					<p style="font-size: 13px;overflow: hidden; white-space: nowrap;text-overflow: ellipsis;margin: 6px 0px;" :title="item.name+'('+item.code+')'">{{item.name}}({{item.code}})</p>
					<p v-if="item.operateStatus=== 'Normal'">
						<p v-if="item.faultStatus === 'Invalid'">
							<i style="color: #ff0000">故障(
							<i v-if="item.machineStatus === 'Online'" style="color: #62c462">在线</i>
							<i v-else style="color: #666">离线</i>
							)</i>
						</p>
						<p v-else>
							<i v-if="item.machineStatus === 'Online'" style="color: #62c462">在线</i>
							<i v-else style="color: #666">离线</i>
						</p> 
					</p>
					<p v-if="item.operateStatus=== 'Stopped'"> 
						<i style="color: #666">停售</i>
					</p>
				</div>
			</div>
		</div>
		<div class="m_common_bottom">
			<m-pagination @pageChange="pageChange" :total="total"></m-pagination>
		</div>
		<el-dialog :close-on-click-modal="false" title="分配" :visible.sync="distributionDialogVisible" :modal="false" v-el-drag-dialog width="600px">
			<div class="m_dialog_top" :style="dialogTopStyle">
				<el-form ref="distributionForm" :model="distributionForm" label-width="auto" :rules="rules" style="padding-right: 10px">
					<el-form-item label="加盟商" prop="operator">
						<el-select v-model="distributionForm.operator" placeholder="请选择" clearable>
							<el-option :label="item.name" :value="item.id" v-for="(item, i) in operatorsList" :key="i">
							</el-option>
						</el-select>
					</el-form-item>
				</el-form>
			</div>
			<div class="m_dialog_bottom">
				<el-button type="primary" @click="saveDistribution" size="mini" class="m_btn_save">保存</el-button>
				<el-button @click="distributionDialogVisible = false" size="mini" class="m_btn_cancel">取消</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import elDragDialog from "@/directive/el-drag-dialog";
	import mPagination from "@/components/pagination/index.vue";
	import { PAGE_SIZE,XHR_URL } from "@/utils/config";
	import {
		machinesSelect,
		machinesInsert,
		machinesUpdate,
		machinesDelete,
		machinecatasSelect,
		machinesFranchi,
		machinesOperator,
		machinesValid,
		machinesInvalid,
		rebootSecret,
		machinesExport
	} from "@/api/equipment";
	import { regions } from "@/api/base";
	import { operatorsSelect } from "@/api/franchisee";
	export default {
		directives: {
			elDragDialog
		},
		data() {
			const _this = this;
			return {
				total: 0,
				currentPage: 1,
				cascaderValue: "",
				franchiseeId: null,
				machstatus: null,
				operstatus: null,
				status: null,
				treeData: [],
				cascaderProps: {
					lazy: true,
					checkStrictly: true,
					async lazyLoad(node, resolve) {
						const result = await _this.lazyLoad(node);
						resolve(result);
					},
				},
				tableData: [],
				contentModel: "table",
				map: null,
				distributionDialogVisible: false,
				rules: {
					operator: [{
						required: true,
						message: "请选择加盟商",
						trigger: "change"
					}]
				},
				distributionForm: {
					franchi: "",
					operator: "",
					id: "",
				},
				operatorsList: [],
				franchiseeList: []
			};
		},
		props: {},
		computed: {
			pageSize() {
				return PAGE_SIZE;
			},
			dialogTopStyle() {
				return {
					maxHeight: document.body.offsetHeight * 0.6 + "px",
				};
			},
			fileUrl(){
				return XHR_URL;
			}
		},
		components: {
			mPagination,
		},
		async created() {
			if(typeof(this.$route.query.machstatus) !='undefined'){
		  		this.machstatus=this.$route.query.machstatus;
		   		this.search();
		  }else{
				this.select();
		  	}
			this.operatorsLoad();
			const res = await this.regions();
			this.treeData = res;
		},
		mounted() {},
		methods: {
			pageChange(page) {
				this.currentPage = page;
				this.search();
			},
			search() {
				const queryData = {
					province: this.cascaderValue && this.cascaderValue[0] ? this.cascaderValue[0] : null,
					city: this.cascaderValue && this.cascaderValue[1] ? this.cascaderValue[1] : null,
					county: this.cascaderValue && this.cascaderValue[2] ? this.cascaderValue[2] : null,
					oper: this.franchiseeId && String(this.franchiseeId).length > 0 ?
						this.franchiseeId : null,
					machstatus: this.machstatus && String(this.machstatus).length > 0 ?
						this.machstatus : null,
					operstatus: this.operstatus && String(this.operstatus).length > 0 ?
						this.operstatus : null,
//					status: this.status && String(this.status).length > 0 ? this.status : null,
					faultstatus:this.status && String(this.status).length > 0 ? this.status : null,
				};
				this.select(queryData);
			},
			exportData() {
				const queryData = {
					province: this.cascaderValue && this.cascaderValue[0] ? this.cascaderValue[0] : null,
					city: this.cascaderValue && this.cascaderValue[1] ? this.cascaderValue[1] : null,
					county: this.cascaderValue && this.cascaderValue[2] ? this.cascaderValue[2] : null,
					oper: this.franchiseeId && String(this.franchiseeId).length > 0 ?
						this.franchiseeId : null,
					machstatus: this.machstatus && String(this.machstatus).length > 0 ?
						this.machstatus : null,
					operstatus: this.operstatus && String(this.operstatus).length > 0 ?
						this.operstatus : null,
					faultstatus:this.status && String(this.status).length > 0 ? this.status : null,
				};
				this.exportFile(queryData);
			},
			changeModel(model) {
				this.contentModel = model;
			},
			showUpdate(data) {
				this.$router.push({
					name: 'vmInfo',
					query: {
						id: data.id,
						code: data.code
					}
				});
			},
			distributionPartner(data) {
				this.distributionDialogVisible = true;
				this.distributionForm.id = data.id;
				this.distributionForm.franchi = data.franchi.id;
				this.operatorsSelect();
				this.distributionForm.operator = data.operator.id;
			},
			saveDistribution() {
				this.$refs.distributionForm.validate((valid) => {
					if(valid) {
						//						this.machinesFranchi();
						this.machinesOperator();
					} else {
						return false;
					}
				});
			},
			async select(queryData) {
				queryData = queryData ? queryData : {};
				queryData.pageNo = this.currentPage;
				queryData.pageSize = this.pageSize;
				const res = await machinesSelect(queryData);
				const {
					pageCount,
					results
				} = res;
				this.tableData = results;
				this.total = pageCount.allCount;
			},
			async exportFile(queryData) {
				queryData = queryData ? queryData : {};
				const res = await machinesExport(queryData);
				const {
					pageCount,
					results
				} = res;
				const a = document.createElement("a");
				const e = document.createEvent("MouseEvents");
				e.initEvent("click", false, false);
				a.href = this.fileUrl + results[0].url;
				a.download = results[0].fileName;
				a.dispatchEvent(e);
			},
			async operatorsLoad() {
				const res = await operatorsSelect({
					franchi: null,
				});
				const {
					pageCount,
					results
				} = res;
				this.franchiseeList = results;
			},
			async operatorsSelect() {
				const res = await operatorsSelect({
					franchi: this.distributionForm.franchi,
				});
				const {
					pageCount,
					results
				} = res;
				this.operatorsList = results;
			},
			async machinesFranchi() {
				const res = await machinesFranchi({
					id: this.distributionForm.id,
					franchi: this.distributionForm.franchi,
				});
				const {
					pageCount,
					results
				} = res;
				this.distributionDialogVisible = false;
			},
			async machinesOperator() {
				const res = await machinesOperator({
					id: this.distributionForm.id,
					operator: this.distributionForm.operator,
				});
				const {
					pageCount,
					results
				} = res;
				this.distributionDialogVisible = false;
				this.$message({
					message: "添加成功",
					type: "success",
				});
			},
			machinesValid(data) {
				this.$confirm("确认设备恢复销售？")
					.then(async() => {
						const res = await machinesValid({
							id: data.id,
						});
						const {
							pageCount,
							results
						} = res;
						this.$message({
							message: "操作成功",
							type: "success",
						});
						this.select();
					})
					.catch((_) => {});
			},
			machinesInvalid(data) {
				this.$confirm("确认停售设备？")
					.then(async() => {
						const res = await machinesInvalid({
							id: data.id,
						});
						const {
							pageCount,
							results
						} = res;
						this.$message({
							message: "操作成功",
							type: "success",
						});
						this.select();
					})
					.catch((_) => {});
			},
			RebootSecret(data){
				this.$confirm("确认重置秘钥？")
					.then(async() => {
						const res = await rebootSecret({
							id: data.id,
						});
						const {
							pageCount,
							results
						} = res;
						this.$message({
							message: "操作成功",
							type: "success",
						});
						this.select();
					})
					.catch((_) => {});
				
			},
			async regions(data, level) {
				const res = await regions(data);
				const {
					pageCount,
					results
				} = res;
				let list = [];
				results.forEach((item) => {
					list.push({
						value: item,
						label: item,
						leaf: level === 2,
					});
				});
				return list;
			},
			async lazyLoad(node) {
				const {
					level
				} = node;
				let query = null;
				switch(level) {
					case 1:
						query = {
							province: node.path[0],
						};
						break;
					case 2:
						query = {
							province: node.path[0],
							city: node.path[1],
						};
						break;
				}
				if(!query) return;
				const res = await this.regions(query, level);
				return res;
			},
		},
		beforeDestroy() {},
		destroyed() {},
	};
</script>
<style lang="less" scoped>
	@import "../../assets/css/common/common.less";
	.inputs {
		width: 12%;
	}
</style>
<style lang="less">
	@import "../../assets/css/checkdata/checkdata.less";
</style>